<script setup lang="ts">
/**
 * @file Icon-only button. A shortcut for using OzContainedButton with only an icon.
 * Allows using a different icon for active state and automatically paints it
 * using selection color.
 */
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonHrefMode,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import { VueCssClass } from '@@/types'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    label: string
    iconName: string
    iconFont?: 'immaterial' | 'oricon'
    iconSize?: number
    sizePreset?: OzContainedButtonSizePreset
    colorScheme?: OzContainedButtonColorScheme
    darkMode?: boolean | 'auto'
    /**
     * Use a different icon when the button is active.
     */
    activeIconName?: string
    disabled?: boolean
    href?: string
    hrefMode?: OzContainedButtonHrefMode
    /**
     * Customizes the gap color between the button and the focus ring.
     */
    ringOffsetColorClasses?: VueCssClass
  }>(),
  {
    iconFont: 'oricon',
    iconSize: undefined,
    sizePreset: OzContainedButtonSizePreset.H40px,
    colorScheme: OzContainedButtonColorScheme.Primary,
    darkMode: 'auto',
    activeIconName: undefined,
    disabled: false,
    href: undefined,
    hrefMode: OzContainedButtonHrefMode.Default,
    ringOffsetColorClasses: undefined,
  },
)

const isActive = computed((): boolean => {
  return props.colorScheme
    ? [OzContainedButtonColorScheme.SecondaryActive, OzContainedButtonColorScheme.SecondaryClearActive].includes(
        props.colorScheme,
      )
    : false
})

const presetIconSize = computed((): number => {
  switch (props.sizePreset) {
    case OzContainedButtonSizePreset.H40px:
      return 16
    case OzContainedButtonSizePreset.H32px:
      return 20
    case OzContainedButtonSizePreset.H24px:
      return 16
    default:
      return 24
  }
})
</script>

<script lang="ts">
export {
  OzContainedButtonColorScheme as OzIconButtonColorScheme,
  OzContainedButtonHrefMode as OzIconButtonHrefMode,
  OzContainedButtonSizePreset as OzIconButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'

export default {}
</script>

<template>
  <OzContainedButton
    :aria-label="label"
    :title="label"
    :dark-mode="darkMode"
    :color-scheme="colorScheme"
    :size-preset="sizePreset"
    :disabled="disabled"
    :href-mode="hrefMode"
    :href="href"
    :ring-offset-color-classes="ringOffsetColorClasses"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @mousedown="$emit('mousedown', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @click="$emit('click', $event)"
    @pointerdown="$emit('pointerdown', $event)"
  >
    <template #icon>
      <OzIcon
        :name="isActive && activeIconName ? activeIconName : iconName"
        :font="iconFont"
        :size="iconSize || presetIconSize"
      />
    </template>
  </OzContainedButton>
</template>
